<template>
  <div class="main">
    <a-row type="flex">
      <a-col flex="200px" class="item-list">
        <a-page-header title="搜索" @back="() => $router.go(-1)" />
        <!-- <div class="tips">项目管理</div> -->
        <a-tabs
          default-active-key="0"
          v-model="type"
          tab-position="left"
          @change="ckTabs"
        >
          <a-tab-pane :key="0">
            <a-row
              type="flex"
              justify="space-between"
              slot="tab"
              style="width: 200px"
            >
              <a-col>项目</a-col>
              <a-col>{{ count.projectCount }}</a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane :key="1">
            <a-row
              type="flex"
              justify="space-between"
              slot="tab"
              style="width: 200px"
            >
              <a-col>任务</a-col>
              <a-col>{{ count.taskCount }}</a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane :key="2">
            <a-row
              type="flex"
              justify="space-between"
              slot="tab"
              style="width: 200px"
            >
              <a-col>项目文件</a-col>
              <a-col>{{ count.fileCount }}</a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane :key="3">
            <a-row
              type="flex"
              justify="space-between"
              slot="tab"
              style="width: 200px"
            >
              <a-col>知识库</a-col>
              <a-col>{{ count.wikiCount }}</a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane :key="4">
            <a-row
              type="flex"
              justify="space-between"
              slot="tab"
              style="width: 200px"
            >
              <a-col>附件</a-col>
              <a-col>{{ count.wikiFileCount }}</a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </a-col>
      <a-col flex="auto">
        <div class="list-content">
          <a-list
            item-layout="horizontal"
            :data-source="thatData"
            class="card-list-info"
            :pagination="pagination"
          >
            <a-list-item
              slot="renderItem"
              slot-scope="item"
              style="padding: 10px 20px"
            >
              <a-list-item-meta @click="goProject(item.id)" v-if="type==0">
                <a-row
                  slot="title"
                  style="color: #1890ff; font-size: 18px; line-height: 40px"
                  >{{ item.name }}<span class="down" v-if="item.filePath!=null" @click="file(item.filePath)"><a-icon type="arrow-down" style="font-size:18px;color:#1890FF;" v-if="type==2||type==4" /></span></a-row
                >
                <a-row slot="title">工作ID：{{ item.id }}</a-row>
                <!-- <a-row slot="title" style="line-height: 50px"
                  >描述：{{ item.content }}</a-row
                > -->
                <a-row slot="title">所属项目: {{ item.projectName }}</a-row>
              </a-list-item-meta>

              <a-list-item-meta v-if="type==3" @click="goKnowledge">
                <a-row
                  slot="title"
                  style="color: #1890ff; font-size: 18px; line-height: 40px"
                  >{{ item.name }}<span class="down" v-if="item.filePath!=null" @click="file(item.filePath)"><a-icon type="arrow-down" style="font-size:18px;color:#1890FF;" v-if="type==2||type==4" /></span></a-row
                >
                <a-row slot="title">工作ID：{{ item.id }}</a-row>
                <!-- <a-row slot="title" style="line-height: 50px"
                  >描述：{{ item.content }}</a-row
                > -->
              </a-list-item-meta>

              <a-list-item-meta v-if="type==2||type==4||type==1">
                <a-row
                  slot="title"
                  style="color: #1890ff; font-size: 18px; line-height: 40px"
                  >{{ item.name }}<span class="down" v-if="item.filePath!=null" @click="file(item.filePath)"><a-icon type="arrow-down" style="font-size:18px;color:#1890FF;" v-if="type==2||type==4" /></span></a-row
                >
                <a-row slot="title">工作ID：{{ item.id }}</a-row>
                <!-- <a-row slot="title" style="line-height: 50px"
                  >描述：{{ item.content }}</a-row
                > -->
                <a-row slot="title">所属项目: {{ item.projectName }}</a-row>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { seachInfo, seachCount, fileList } from "@/httpsAPI/search";
import { downloadCommonFile } from "@/httpsAPI/knowledgeBase/index"
import { coludDownloadFile } from '@/httpsAPI/common'
export default {
  data() {
    return {
      filePath:'',
      fileName:'',
      type: 0,
      data: [],
      thatData: [],
      count: {},
      pageSize: 10,
      pageIndex: 1,
      pagination: {
        onChange: (page) => {
          this.pageIndex = page;
          this.getDetailInfo(this.type);
        },
        pageSize: 10,
        total: 0,
        showSizeChanger: false,
        current: 1,
      },
    };
  },
  created(){

  },
  watch: {
    $route() {
      this.type = 0;
      this.initData();
    },
  },
  mounted() {
    this.type = 0;
    this.initData();
  },
  methods: {
    // 文件下载
    file(filePath){
      fileList({filePath:filePath}).then((res)=>{
        this.filePath = res.data.filePath,
        this.fileName = res.data.fileName
          let link = document.createElement('a')
          link.style.display = 'none'
          link.setAttribute('download', this.fileName)
          link.href = this.filePath
          link.target ="_blank"
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link);
      })
    },
    goProject(project){
      this.$router.push({path:"/projectManage/overview",query:{projectId:project}})
    },
    goKnowledge(){
      this.$router.push({path:"/knowledge/myself"})
    },
    initData() {
      seachCount({
        kw: this.$route.params.kw,
      }).then((res) => {
        console.log("获取总数", res);
        this.count = res.data;
        this.getDetailInfo(0);
      });
    },
    getDetailInfo(type) {
      seachInfo({
        pageNo: this.pageIndex,
        pageSize: this.pageSize,
        kw: this.$route.params.kw,
        type: type,
      }).then((res) => {
        this.thatData = res.data.data.map((res,index) => {
          this.idx = index
          let _c = res.content ? res.content.replace(/<[^>]+>/g, "") : "";
          return {
            ...res,
            content: _c.replace(/&nbsp;/gi, ""),
          };
        });
        this.pagination.pageSize = this.pageSize;
        this.pagination.total = res.data.totalCount;
        this.pagination.showTotal = () => `共 ${res.data.totalCount} 条`;
        this.pagination.showSizeChanger = true;
        this.pagination.current = this.pageIndex;
      });
    },
    ckTabs(key) {
      this.type = key;
      console.log(this.type,"this.type")
      this.pageIndex = 1;
      this.getDetailInfo(key);
    },
  },
};
</script>
<style>
.main {
}
.item-list {
  background-color: #eeee;
}
.main .ant-tabs-tab-active {
  background-color: #fff !important;
}
.main .tips {
  padding: 10px 20px;
  font-size: 12px;
}
.list-content {
  padding: 20px;
  box-sizing: border-box;
}
.card-list-info {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 10px 20px 10px;
}
.down{
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px #1890FF solid;
  border-radius: 50%;
  margin-left: 10px;
  align-items: middle;
  line-height: 0;
}
</style>